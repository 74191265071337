import { DeviceOptions } from './device';
import { LeakbotTestimonials, PhynTestimonials, NotionTestimonials, TingTestimonials, ResideoTestimonials } from './testimonials';
import { NotionFAQs, TingFAQs, PhynFAQs, LeakBotFAQs, ResideoFAQs } from './FAQs';

/*  TODO: NOTE FOR PRE-DEVELOPMENT: Any string that needs an HTML element (such as making something bold, italics, etc.)
    will need to be put directly into the HTML and removed from this list. There are ways to interpolate the info, but
    it introduces security risks and are discouraged.*/

export const DeviceStaticInfo = {
  leakbot: {
    name: 'LeakBot',
    deviceHeaderText: 'Help protect your home from water damage',
    installationMethod: "",
    deviceOptions: {
      orderOptions: [
        {
          name: "order",
          buttonText: "Order",
          selected: true,
        },
      ]
    },
    faqs: LeakBotFAQs,
    howDeviceWorksValues: {
      howDeviceWorksVideo: {
        videoPath: "https://player.vimeo.com/video/284733852?h=0808bd9bf2&color=f0c319&title=0&byline=0&portrait=0",
        title: "How it works",
        subtitle: "",
      },
    },
    deviceInstallationVideo: undefined,
    howDeviceInstalledText: "A person will come to your home at the scheduled date and time.",
    testimonials: LeakbotTestimonials,
    disclaimer: `Device availability varies by state and Nationwide may discontinue this program at any time. The smart home monitoring system and related support will be provided by the third-party device provider and maker of LeakBot. Nationwide is not responsible for the system or any home monitoring.`,
    deviceType: 'water',
    numberOfDaysToInstall: 55,
    offerDescription: 'This offer also includes plumbing repair services at no additional charge',
    offerImagePath: '../../../assets/images/LeakBot-marketing.png',
    vendorTitle: 'LeakBot'
  },
  phyn: {
    name: 'Phyn',
    deviceHeaderText: 'Protect the comfort of your home from hidden leaks, electrical sparks and unexpected threats',
    installationMethod: "",
    deviceOptions: {
      orderOptions: [
        {
          name: "order",
          buttonText: "Order",
          selected: true,
        }
      ]
    },
    faqs: PhynFAQs,
    howDeviceWorksValues: {
      howDeviceWorksVideo: {
        videoPath: "https://player.vimeo.com/video/639308222?h=d15bb1dddd",
        title: "How it works",
        subtitle: "",
      },
    },
    deviceInstallationVideo: {
      videoPath: "https://player.vimeo.com/video/330931800?h=da7007e408",
      title: "How it is installed",
      subtitle: "",
    },
    howDeviceInstalledText: "A person will come to your home at the scheduled date and time.",
    testimonials: PhynTestimonials,
    disclaimer: `Device availability varies by state and Nationwide may discontinue this program at any time. The smart home monitoring system and related support will be provided by the third-party device provider and maker of Phyn. The In-home Plumbing Protection Plan and related support will be provided by American Water Resources, LLC. Nationwide is not responsible for the system or any home monitoring.`,
    deviceType: 'water',
    numberOfDaysToInstall: 55,
    offerDescription: 'This offer also includes an American Water Resources 1-year In Home Plumbing Protection Plan at no additional charge',
    offerImagePath: '../../../assets/images/phyn-landing.png',
    vendorTitle: 'Phyn'
  },
  // depassa1 - remove this notion object and update notionOriginal object name to "notion" when notion is done being dumb
  notion: {
    doNotDisplayVendorInfo: true,
    name: 'Notion',
    deviceHeaderText: 'Help protect your home from water damage and more.',
    installationMethod: "",
    deviceOptions: {
      orderOptions: [
        {
          name: "order",
          buttonText: "Order",
          selected: true,
        }
      ]
    },
    faqs: NotionFAQs,
    howDeviceWorksValues: {},
    deviceInstallationVideo: undefined,
    howDeviceInstalledText: "A person will come to your home at the scheduled date and time.",
    testimonials: NotionTestimonials,
    disclaimer: `Device availability varies by state and Nationwide may discontinue this program at any time. The smart home monitoring system and related support will be provided by a third-party device manufacturer and system provider. Nationwide is not responsible for the system or any home monitoring.`,
    deviceType: 'water',
    numberOfDaysToInstall: 55,
    paidDeviceWebsite: 'https://notion-nationwide.myshopify.com',
    offerImagePath: '',
    vendorTitle: ''
  },
  notionOriginal: {
    name: 'Notion',
    deviceHeaderText: 'Help protect your home from water damage, break-ins, fires and more.',
    installationMethod: "",
    deviceOptions: {
      orderOptions: [
        {
          name: "order",
          buttonText: "Order",
          selected: true,
        }
      ]
    },
    faqs: NotionFAQs,
    howDeviceWorksValues: {
      cardInfo: [
        {
          pathToImg: '../../../../assets/images/icon-notionCardSensor.svg',
          title: 'Sensors',
          description: 'Monitors for water leaks, opening doors and windows, sounding alarms and temperature changes at your property'
        },
        {
          pathToImg: '../../../../assets/images/icon-notionCardBridge.svg',
          title: 'Bridge',
          description: 'Connects to your Wi-Fi to relay information from each sensor to the app'
        },
        {
          pathToImg: '../../../../assets/images/icon-notionCardApp.svg',
          title: 'App',
          description: 'Allows you to receive alerts and manage your system from anywhere'
        }
      ]
    },
    deviceInstallationVideo: undefined,
    howDeviceInstalledText: "A person will come to your home at the scheduled date and time.",
    testimonials: NotionTestimonials,
    disclaimer: `Device availability varies by state and Nationwide may discontinue this program at any time. The smart home monitoring system and related support will be provided by Notion, the third-party device manufacturer and system provider. Nationwide is not responsible for the system or any home monitoring.`,
    deviceType: 'water',
    numberOfDaysToInstall: 55,
    paidDeviceWebsite: 'https://notion-nationwide.myshopify.com',
    offerImagePath: '../../../assets/images/notion-land.png',
    vendorTitle: 'Notion 3+1 Starter Kit'
  },
  resideo: {
    doNotDisplayVendorInfo: true,
    name: 'Resideo',
    deviceHeaderText: 'Help protect your home from water damage and more.',
    installationMethod: "",
    deviceOptions: {
      orderOptions: [
        {
          name: "order",
          buttonText: "Order",
          selected: true,
        }
      ]
    },
    faqs: ResideoFAQs,
    howDeviceWorksValues: {},
    deviceInstallationVideo: undefined,
    howDeviceInstalledText: "A person will come to your home at the scheduled date and time.",
    testimonials: ResideoTestimonials,
    disclaimer: `Device availability varies by state and Nationwide may discontinue this program at any time. The smart home monitoring system and related support will be provided by a third-party device manufacturer and system provider. Nationwide is not responsible for the system or any home monitoring.`,
    deviceType: 'water',
    numberOfDaysToInstall: 55,
    paidDeviceWebsite: 'https://vip.resideo.com/collections/nationwide',
    offerImagePath: '',
    vendorTitle: ''
  },
  ting: {
    name: 'Ting',
    deviceHeaderText: 'Protect the comfort of your home from hidden leaks, electrical sparks and unexpected threats',
    installationMethod: "",
    deviceOptions: {
      orderOptions: [
        {
          name: "order",
          buttonText: "Order",
          selected: true,
        }
      ]
    },
    faqs: TingFAQs,
    howDeviceWorksValues: {
      howDeviceWorksVideo: {
        videoPath: "https://player.vimeo.com/video/354300475?h=0808bd9bf2&color=f0c319&title=0&byline=0&portrait=0",
        title: "How it works",
        subtitle: "",
      },
    },
    deviceInstallationVideo: undefined,
    howDeviceInstalledText: "A person will come to your home at the scheduled date and time.",
    testimonials: TingTestimonials,
    disclaimer: `Device availability varies by state and Nationwide may discontinue this program at any time. The smart home monitoring system and related support will be provided by Whisker Labs, the third-party device manufacturer and system provider. Nationwide is not responsible for the system or any home monitoring.`,
    deviceType: 'fire',
    numberOfDaysToInstall: 55,
    offerDescription: 'This offer also includes a 3yr monitoring service at no additional charge',
    offerImagePath: '../../../assets/images/ting-img-01 copy.png',
    vendorTitle: 'Ting'
  },
}

export type DeviceInfo = {
  name: string;
  deviceHeaderText: string;
  installationMethod: string;
  deviceOptions: DeviceOptions;
  faqs: QA[];
  howDeviceWorksValues: HowDeviceWorks;
  deviceInstallationVideo: VideoInfo | undefined;
  howDeviceInstalledText: string;
  testimonials: Testimonial[];
  disclaimer?: string;
  deviceType: string;
  numberOfDaysToInstall: number;
  paidDeviceWebsite?: string;
  offerDescription?: string;
  offerImagePath?: string;
  vendorTitle: string;
}

export type QA = {
  question: string;
  answer: string;
}

export type VideoInfo = {
  videoPath: string;
  title: string;
  subtitle: string;
}

export type Testimonial = {
  quote: string;
  name: string;
  date: string;
}

export type HowDeviceWorks = {
  howDeviceWorksVideo?: VideoInfo;
  cardInfo?: HowItWorksCardInfo[];
}

export type HowItWorksCardInfo = {
  pathToImg: string;
  title: string;
  description: string;
}
